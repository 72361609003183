import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';
import { Slide } from 'react-reveal';
import Collapse,{ CollapseItem} from "../components/collapse/collapse";
import TeamCard from "../components/team";

//videos
import video_mp4 from "../images/video/Optosweden hem.mp4";

function About(){
        return(
        <Layout>
            <Helmet>
                <title>Om oss | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi levererar högkvalitativ, professionell och tekniskt kunnig supporthjälp till din skanner" />
                <meta name="keywords" content="Support | Sälj" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.optosweden.se/om-oss" />
                <link rel="cannonical" href="http://www.optosweden.se/om-oss" />
            </Helmet>

            <section className="bg-gray-100 pt-4">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="bg-kollegor-banner bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden" >
                        <div className="w-full h-full" style={{background: "linear-gradient(140deg,  rgba(190, 209, 17, 0.25), rgba(29, 95, 182, 0.5) 30%)"}}>
                            <h1 className="pt-40 pb-32  text-4xl lg:text-5xl font-bold text-white text-center">OptoSweden</h1>
                            <Wave 
                                className="-mb-2"
                                fill='#F3F4F6'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 50,
                                speed: 0.15,
                                points: 3
                                }}
                            />  
                        </div>
                    </div>
                </div>
            </section>

            {/***********  About Section - START  **********/}
            <section className="bg-gray-100 py-24">
                <div className="container mx-auto px-4 xl:px-24">
                <div className="grid lg:grid-cols-2 gap-20">

                    <Slide left>
                    <div className="w-full">
                        <h1 className="title-font font-semibold text-3xl text-gray-900">Kan ett modernt företag ha över 25 års erfarenhet?</h1>
                        <p className="leading-relaxed mt-4 text-gray-600">
                        Svaret är enkelt- JA! OptoSweden startade 1994 och har över 25 års erfarenhet.
                        Vi har med våra leveranser såväl som till statlig myndighet som till det lilla 
                        bolaget en enorm erfarenhet av marknaden vi verkar på.
                        <br/><br/>
                        Vår koncern omsätter idag närmare 100 miljoner per år och är trippel AAA 
                        rejtade sedan flera år tillbaka, vilket innebär att du får en finansiellt stark 
                        leverantör som också älskar teknik. Våra kunder får alltså ta del av den modernaste 
                        teknologin som finns på marknaden samtidigt som de kan förlita sig på att vi 
                        har kunskapen och kompetensen att optimera deras dokumenthantering.
                        <br/><br/>
                        Vi vill göra din resa inom digitalisering av dokument enkel, låt oss ta med dig i framtiden.
                        </p>

                    </div>
                    </Slide>

                    <Slide right>
                        <div className="hidden lg:block w-full">
                            <video src={video_mp4} className="w-full h-full object-cover object-center rounded-lg" autoPlay muted loop/>
                        </div>
                    </Slide>
                </div>
                <div className="w-full lg:w-1/2 mt-8 lg:pr-8">
                        <Collapse collapse_text="Policy">
                            <CollapseItem 
                                href="/pdf/informationssakerhetspolicy" 
                                pdf_name="Informationssäkerhetspolicy"
                            />
                            <CollapseItem 
                                href="/pdf/kvalitetspolicy" 
                                pdf_name="Kvalitetspolicy"
                            />
                            <CollapseItem 
                                href="/pdf/miljopolicy" 
                                pdf_name="Miljöpolicy"
                            />
                        </Collapse>   
                    </div>
                    <div className="w-full lg:w-1/2 mt-8 lg:pr-8">
                        <Collapse collapse_text="Villkor och Integritet">
                            <CollapseItem 
                                href="/pdf/integritetspolicy" 
                                pdf_name="Integritetspolicy"
                            />
                        </Collapse>   
                    </div>
                </div>
            </section>
            {/***********  About Section - END  **********/}

            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid md:grid-cols-2 gap-12 ">
                        <TeamCard 
                            name="OptoSweden Front Office"
                            title="Huvudväxel"
                            number="08-410 360 98"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Sälj</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 gap-12">
                        <TeamCard 
                            name="Lars Hjortlinder"
                            title="Senior Solution Sales"
                            number="08-578 604 08"
                            email="lars.hjortlinder@optosweden.se"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Projekt och Utveckling</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 gap-12">
                        <TeamCard 
                            name="Susanna Wester"
                            title="Projektledare"
                            number="08-578 604 04"
                            email="susanna.wester@optosweden.se"
                        />
                                                
                        <TeamCard 
                            name="Robin Ellery"
                            title="Teknisk Projektledare"
                            number="08-578 604 16"
                            email="robin.ellery@optosweden.se"
                        />
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 gap-12">
                        <TeamCard 
                            name="Mattias Horn"
                            title="Front-office växel &amp; Support"
                            number="08-410 360 95"
                        />
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 pt-12 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Ledning</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 gap-12">
                        <TeamCard 
                            name="Niklas Horn Lundberg"
                            title="VD"
                        />
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default About;